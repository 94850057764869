//this is not used
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialState = {
    products: [],
    loading: false,
    error: null,
};

export const fetchAllProducts = createAsyncThunk(
    'products/fetchAllProducts',
    async () => {
        try {
               const response = await fetch('https://banknoteconsult.com/data_test/Products_Api/getAllProducts.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                }
                // body: JSON.stringify({ business_type: 'T' }), // Sending businessType as part of the request
            });
            if (!response.ok) {
                throw new Error('Failed to fetch products');
            }
            const data = await response.json();
            return data;
        } catch (error) {
            throw new Error('Failed to fetch products');
        }
    }
);

const productsSlice = createSlice({
    name: 'products',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllProducts.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchAllProducts.fulfilled, (state, action) => {
                state.loading = false;
                state.products = action.payload;
            })
            .addCase(fetchAllProducts.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

// Export actions and reducer
export const productsActions = productsSlice.actions;
export const productsReducer = productsSlice.reducer;

// Selectors
export const selectProductsIndexed = (state) => {
    return state.products.products.map((product, index) => ({ ...product, index }));
};
