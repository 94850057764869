import React, { createContext, useState, useEffect } from 'react';
// import axios from 'axios';
export const CategoriesContext = createContext();
export const CategoriesProvider = ({ children }) => {

    const [publicSubCate, setPublicSubCate] = useState([]);

    useEffect(() => {
        getAllSubCate()
    }, [])
    

    const getAllSubCate=async () => {
        try {
            const response = await fetch('https://banknoteconsult.com/data_test/subCategory_getAll.php', {
                method: 'POST',
                body: new URLSearchParams({}),
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            });
            if (!response.ok) {
                throw new Error('Failed to fetch subcategories');
            }
            const data = await response.json();
            setPublicSubCate(data)
            return data;
        } catch (error) {
            throw new Error('Failed to fetch subcategories');
        }
    }

    return (
        <CategoriesContext.Provider value={{ publicSubCate }}>
            {children}
        </CategoriesContext.Provider>
    );
};
