import React, { lazy, Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom';
import './index.css';
import store from './Store';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import { CategoriesProvider } from './context/categoriesContext';
const root = createRoot(document.getElementById('root'));
const App = lazy(() => import('./App'));
root.render(
  
  <Provider store={store}>
    <CategoriesProvider>
    <BrowserRouter>
      <Suspense fallback=
        {<div></div>}>
        <App />
      </Suspense>

    </BrowserRouter>
  </CategoriesProvider>
  </Provider>

);
