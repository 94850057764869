import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialState = {
    categories: [],
    loading: false,
    error: null,
};

export const fetchCategories = createAsyncThunk(
    'categoriesInfo/fetchCategories',
    async () => {
        try {
//            const response = await fetch('https://data.banknoteconsult.com/getCatwithSubwithCount.php');
            const response = await fetch('https://banknoteconsult.com/data_test/getCatwithSubwithCount.php');
            if (!response.ok) {
                throw new Error('Failed to fetch categories');
            }
            const data = await response.json();
            return data;
        } catch (error) {
            throw new Error('Failed to fetch categories');
        }
    }
);

const categoriesSlice = createSlice({
    name: 'categoriesInfo',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchCategories.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchCategories.fulfilled, (state, action) => {
                state.loading = false;
                state.categories = action.payload.map(category => ({
                    id: category.id,
                    name: category.name,
                    count: category.total_product_count,
                    subCategories: category.subCategories.map(subCategory => ({
                        id: subCategory.id,
                        name: subCategory.name
                    }))
                }));
            })
            .addCase(fetchCategories.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    }
});

export const categoriesActions = categoriesSlice.actions;
export const categoriesInfoReducer = categoriesSlice.reducer;
